import { logFirebaseEvent } from 'analytics/firebase';
import cn from 'classnames';
import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useState, FC } from 'react';

import { Alert, EStatus } from 'components/UIKit/Alert';
import { T } from 'components/basic/Typography';

import { doFetch } from 'utils/doFetch';

import s from './MailConfirmation.module.scss';

type TMailConfirmationProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const MailConfirmation: FC<TMailConfirmationProps> = ({ className }) => {
  const [isEmailSent, setIsEmailSent] = useState(false);

  async function resendMail(): Promise<void> {
    const apiPointName = 'resendMail';
    const {
      result: { success },
    } = await doFetch<typeof apiPointName>({
      apiPointName,
    });

    if (success) {
      setIsEmailSent(true);
    }
    logFirebaseEvent('main_click_notification');
  }

  const sendEmail = isEmailSent ? (
    <span>
      <T>Письмо отправлено!</T>
    </span>
  ) : (
    <span>
      На вашу почту отправлено письмо с активацией. Перейдите по ссылке из
      письма в течение недели. Если не успели, запросите письмо еще раз.&nbsp;
      <span className={s.link} onClick={resendMail}>
        Отправить письмо повторно
      </span>
    </span>
  );

  return (
    <div className={cn('g-col-12', s.container, className)}>
      <Alert
        type={isEmailSent ? EStatus.success : EStatus.info}
        wrapper
        textInformation={sendEmail}
      />
    </div>
  );
};
