import cn from 'classnames';
import { useState, FC } from 'react';

import s from './AgreementBanner.module.scss';

interface IAgreementBannerProps {
  setAgreementCookie: () => void;
  className?: string;
}

export const AgreementBanner: FC<IAgreementBannerProps> = ({
  setAgreementCookie,
  className,
}) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const initialText = (
    <div>
      <span className={s.text}>
        Продолжая использовать этот сайт, вы даете согласие ООО «Литрес», город
        Москва, на обработку файлов cookie и соответствующих пользовательских
        данных...
      </span>
      <span className={s.link} onClick={(): void => setIsTextExpanded(true)}>
        {' '}
        далее
      </span>
    </div>
  );

  const expandedText = (
    <div>
      <span className={s.text}>
        Продолжая использовать этот сайт, вы даете согласие ООО «Литрес», город
        Москва, на обработку файлов cookie и соответствующих пользовательских
        данных (IP-адрес, сведения о местоположении, тип и версия ОС, тип и
        версия браузера, тип устройства и разрешение экрана, источник прихода на
        сайт, язык операционной системы и браузера, поведение на сайте) в целях
        функционирования сайта, проведения статистических исследований и
        веб-аналитики, в том числе с использованием сторонних сервисов. Если вы
        не хотите, чтобы ваши данные обрабатывались, измените соответствующие
        настройки своего браузера или покиньте сайт.
      </span>
      <a className={cn(s.link, s.readMore)} href="/privacy/" target="_blank">
        {' '}
        Подробнее{' '}
      </a>
    </div>
  );

  return (
    <div className={cn(s.wrapper, className)}>
      <div className={s.banner}>
        {isTextExpanded ? expandedText : initialText}
        <button type="button" onClick={setAgreementCookie} className={s.button}>
          {' '}
          Принять{' '}
        </button>
      </div>
    </div>
  );
};
