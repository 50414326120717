import type { FC } from 'react';

import { Modal, useModal } from 'components/complex/Modal';
import { SpecialOfferForm } from 'components/complex/forms/SpecialOfferForm';
import type { IModalPropsTypes } from 'components/complex/forms/SpecialOfferForm/types';

import { EUiModalTypes } from 'sp-redux/slices/ui/types';

export const DialogSpecialOffer: FC<IModalPropsTypes> = ({
  setSendFormStatus,
}) => {
  const { modalProps } = useModal({
    type: EUiModalTypes.signSpecialOffer,
    title: 'Специальные условия',
    width: '288px',
  });

  return (
    <Modal {...modalProps}>
      <SpecialOfferForm setSendFormStatus={setSendFormStatus} />
    </Modal>
  );
};
