import { useDispatch, useSelector } from 'react-redux';

import type { TAppState } from 'sp-redux';
import { closeModal, openModal } from 'sp-redux/slices/ui';

import type { TUseModalHook } from './types';

export const useModal: TUseModalHook = modalConfig => {
  const dispatch = useDispatch();
  const { isOpenModal, modalType, modalFields } = useSelector(
    (appState: TAppState) => {
      return {
        isOpenModal: appState.ui.isOpenModal,
        modalType: appState.ui.modalType,
        modalFields: appState.ui.modalFields,
      };
    },
  );

  const { type, onClose, onShow, ...restProps } = modalConfig;

  const show = (): void => {
    dispatch(openModal(type));

    if (onShow) {
      onShow();
    }
  };

  const close = (): void => {
    dispatch(closeModal());

    if (onClose) {
      onClose();
    }
  };

  const modalProps = {
    ...restProps,
    isOpen: isOpenModal && modalType === type,
    onRequestClose: close,
    modalFields,
  };

  return {
    isVisible: isOpenModal && modalType === type,
    show,
    close,
    modalProps,
  };
};
