import { useValidation } from 'hooks/useValidation';
import { useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import type { SchemaOf } from 'yup';
import * as yup from 'yup';

import { ALink } from 'components/UIKit/ALink';
import { Button } from 'components/UIKit/Button';
import { Checkbox } from 'components/UIKit/Checkbox';
import { InputText } from 'components/UIKit/InputText';
import { BodyL, H1, T } from 'components/basic/Typography';
import { ModalFooter } from 'components/complex/Modal';

import { closeModal } from 'sp-redux/slices/ui';
import { requestAdvancedStatusThunk } from 'sp-redux/thunks/offer/requestAdvancedStatus';
import { getUserInfoThunk } from 'sp-redux/thunks/userInfo';

import { doFetch } from 'utils/doFetch';

import s from './DialogINN.module.scss';

interface IFormType {
  INN?: string;
}

const INNError = 'Должно быть 12 цифр';

const schema: SchemaOf<IFormType> = yup.object().shape({
  INN: yup.string().min(12, INNError).max(12, INNError).required('Введите ИНН'),
});

export const INNForm: FC = () => {
  const [INN, setINN] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [offerCheck, setOfferCheck] = useState(true);
  const [serverErrors, setServerErrors] = useState<{ INN?: string }>({});
  const dispatch = useDispatch();

  const { validationErrors, validateAllFieldsAsync, debouncedValidate } =
    useValidation<IFormType>({ INN }, schema);

  async function saveINN(): Promise<void> {
    setIsLoading(true);

    await validateAllFieldsAsync();

    if (Object.keys(validationErrors).length > 0 || !INN) {
      setIsLoading(false);
      return;
    }

    const apiPointName = 'advancedOfferAgreement';

    const res = await doFetch<typeof apiPointName>({
      apiPointName: apiPointName,
      body: {
        inn: INN,
      },
    });

    if (res.errors.length > 0 && res.errors[0].field === 'inn') {
      setServerErrors({ INN: res.errors[0].messages[0] });
    } else {
      await dispatch(requestAdvancedStatusThunk());
      await dispatch(getUserInfoThunk());
      dispatch(closeModal());
    }

    setIsLoading(false);
  }

  const _validationErrors = { ...serverErrors, ...validationErrors };

  return (
    <div className={s.root}>
      <H1>
        <BodyL className="mb-4">
          <T>
            Повышение ставок может получить только самозанятый пользователь или
            пользователь с ИП.
          </T>
        </BodyL>
      </H1>
      <InputText
        value={INN}
        labelText="Введите ИНН"
        type="text"
        onChange={(value): void => {
          setServerErrors({});
          setINN(value);
        }}
        debouncedValidate={(): void => {
          debouncedValidate('INN');
        }}
        errorMessage={_validationErrors.INN}
      />
      <Checkbox
        id="offer"
        name="offer"
        checked={offerCheck}
        onChange={setOfferCheck}
        className="mb-4"
        labelText={
          <>
            Я принимаю условия{' '}
            <ALink href="/offer_upgrade/" target="_blank">
              Оферты
            </ALink>
          </>
        }
      />
      <ModalFooter>
        <Button
          size="medium"
          look="primary"
          onClick={saveINN}
          disabled={isLoading || !offerCheck}
        >
          Отправить
        </Button>
      </ModalFooter>
    </div>
  );
};
