import { logFirebaseEvent } from 'analytics/firebase';
import { sendYandexAnalytics } from 'analytics/sendAnalytics';
import type { IApiResponseErrorField } from 'app.types';
import { useValidation } from 'hooks/useValidation';
import { parse } from 'query-string';
import { useState } from 'react';
import type { FC, FormEvent } from 'react';
import * as yup from 'yup';
import type { SchemaOf } from 'yup';

import { pageUrls } from 'constants/pagesUrls';

import { ValidationPasswordText } from 'components/SharedComponents/ValidationPasswordText';
import { ALink } from 'components/UIKit/ALink';
import { Button } from 'components/UIKit/Button';
import { InputText } from 'components/UIKit/InputText';
import { BodyL, BodyM } from 'components/basic/Typography';
import { ModalFooter } from 'components/complex/Modal';

import type {
  IRegistrationRequestType,
  IRegistrationErrorsType,
} from 'sp-redux/slices/userRegistration/types';
import { requestRegistration } from 'sp-redux/thunks/userRegistration/registrationThunk';

import { typografFixText } from 'utils/typograf';

import s from './RegistrationForm.module.scss';

export interface ISignUpFormRequiredFields {
  email: string;
  password: string;
}

const schema: SchemaOf<ISignUpFormRequiredFields> = yup.object().shape({
  email: yup.string().email('Некорректная почта').required('Введите почту'),
  password: yup.string().min(1).required('Введите пароль'),
});

export interface IRegistrationFormProps {
  handleSwitchToSignIn: () => void;
  handleSuccess?: () => void;
  handleManualCloseAfterSuccess?: () => void;
}

export const RegistrationForm: FC<IRegistrationFormProps> = ({
  handleManualCloseAfterSuccess,
  handleSwitchToSignIn,
  handleSuccess,
}) => {
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [otherErrors, setOtherErrors] = useState<
    (IRegistrationErrorsType | undefined)[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { validationErrors, validateAllFieldsAsync, debouncedValidate } =
    useValidation<ISignUpFormRequiredFields>({ email, password }, schema);

  const parseAndShowReqErrors = (errors: IApiResponseErrorField[]): void => {
    const nonFieldErrors: IApiResponseErrorField[] = [];
    errors.forEach((er: IApiResponseErrorField) => {
      if (er.field === 'email') {
        setErrorEmail(er.messages[0]);
      } else if (er.field === 'password') {
        setErrorPassword(er.messages[0]);
      } else {
        nonFieldErrors.push(er);
      }
    });
    setOtherErrors(nonFieldErrors);
  };

  const doRegistration = async (evt: FormEvent): Promise<void> => {
    setIsLoading(true);
    setErrorEmail('');
    setErrorPassword('');
    setOtherErrors([]);
    evt.preventDefault();

    const trimmedPassword = password.replace(/\s/g, '');

    debouncedValidate('email');

    const hasValidationError = await validateAllFieldsAsync();

    if (trimmedPassword.length < 6) {
      setIsLoading(false);
      setErrorPassword('Пароль содержит менее 6 символов');
      return;
    }

    if (hasValidationError) {
      setIsLoading(false);
      return;
    }

    const queryParams = parse(location.search);
    const { referral } = queryParams;

    const requestObject: IRegistrationRequestType = {
      email: email,
      password: trimmedPassword,
    };

    if (referral) {
      requestObject.referral = referral;
    }

    const utmParams = parse(sessionStorage.getItem('utmParams') || '');
    requestObject.params = utmParams;

    await requestRegistration(requestObject)
      .then(response => {
        const { errors } = response;
        if (errors.length) {
          parseAndShowReqErrors(errors);
        } else {
          setIsSuccess(true);
          const action = 'registration_success';
          logFirebaseEvent(action, {
            source: 'registration_form',
          });
          sendYandexAnalytics({
            action,
            goalParams: { user_id: response.result.uuid ?? '' },
          });
          if (handleSuccess) {
            handleSuccess();
          }
        }
      })
      .catch(() => {
        const errorUnknown = [
          { field: 'no fields', messages: 'Что-то пошло не так.' },
        ];
        setOtherErrors(errorUnknown);
      });

    setIsLoading(false);
  };

  const successMessage = `Мы отправили вам письмо на адрес ${email} для подтверждения регистрации!`;

  return (
    <form onSubmit={doRegistration}>
      <div className={s.root}>
        {!isSuccess ? (
          <>
            <InputText
              labelText="Ваша электронная почта"
              value={email}
              onChange={(value): void => {
                setEmail(value);
              }}
              errorMessage={validationErrors.email || errorEmail}
              autocomplete="email"
            />
            <div className={s.passwordWrapper}>
              <InputText
                labelText="Придумайте пароль"
                value={password}
                type="password"
                onChange={(value): void => {
                  setErrorPassword('');
                  setPassword(value.trim());
                }}
                debouncedValidate={(): void => {
                  debouncedValidate('password');
                }}
                errorMessage={validationErrors.password || errorPassword}
                autocomplete="current-password"
              />

              {!(validationErrors.password || errorPassword) && (
                <ValidationPasswordText password={password} />
              )}
            </div>

            {otherErrors.length > 0 &&
              otherErrors.map(er => {
                if (!er) {
                  return;
                }

                return (
                  <BodyL className={s.withError} key={er.messages[0]}>
                    {er.messages}
                  </BodyL>
                );
              })}
          </>
        ) : (
          <BodyL>{typografFixText(successMessage)}</BodyL>
        )}
        <BodyM className={s.footer}>
          При регистрации вы соглашаетесь <br />
          с&nbsp;
          <ALink href={pageUrls.offer.index}>публичной офертой</ALink>.
        </BodyM>
      </div>
      <ModalFooter>
        {!isSuccess ? (
          <>
            <Button
              size="medium"
              look="tertiary"
              onClick={handleSwitchToSignIn}
            >
              Войти
            </Button>
            <Button size="medium" type="submit" disabled={isLoading}>
              Зарегистрироваться
            </Button>
          </>
        ) : (
          <Button
            size="medium"
            onClick={handleManualCloseAfterSuccess}
            disabled={isLoading}
          >
            Закрыть
          </Button>
        )}
      </ModalFooter>
    </form>
  );
};
