import { logFirebaseEvent } from 'analytics/firebase';
import { useValidation } from 'hooks/useValidation';
import type { FormEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import type { SchemaOf } from 'yup';

import { Button } from 'components/UIKit/Button';
import { InputText } from 'components/UIKit/InputText';
import { BodyM, T } from 'components/basic/Typography';
import { ModalFooter } from 'components/complex/Modal';
import { RestoreStepTwo } from 'components/complex/forms/RestorePasswordForm/RestoreStepTwo';

import type { TAppState } from 'sp-redux';
import { restorePasswordThunk } from 'sp-redux/thunks/userRegistration/restorePasswordThunk';

import type { IRestorePasswordParamsType, IProps } from './types';

import s from './RestorePasswordForm.module.scss';

const schema: SchemaOf<IRestorePasswordParamsType> = yup.object().shape({
  email: yup.string().email('Некорректная почта').required('Введите почту'),
});

export const RestorePasswordForm: FC<IProps> = props => {
  const { signUpHandler } = props;
  const dispatch = useDispatch();
  const state = useSelector((st: TAppState) => st.userRegistration);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { validationErrors, validateAllFieldsAsync, debouncedValidate } =
    useValidation<IRestorePasswordParamsType>({ email }, schema);

  useEffect(() => {
    if (state.errors.length > 0) {
      setError(state.errors[0].messages[0]);
    }
    if (state.result && Object.keys(state.result).length > 0) {
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [state]);

  async function doRestore(evt: FormEvent): Promise<void> {
    setIsLoading(true);
    setError('');
    evt.preventDefault();

    await validateAllFieldsAsync();

    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    logFirebaseEvent('password_reset_click_submit');

    try {
      await dispatch(restorePasswordThunk({ email }));
    } catch (err) {
      setError('Что-то пошло не так.');
    }

    setIsLoading(false);
  }

  return !isSuccess ? (
    <form onSubmit={doRestore}>
      <div className={s.root}>
        <InputText
          labelText="Ваша электронная почта"
          value={email}
          onChange={(value): void => {
            setEmail(value);
          }}
          debouncedValidate={(): void => {
            debouncedValidate('email');
          }}
          errorMessage={validationErrors.email || error}
          autocomplete="email"
        />
        <BodyM>
          <T>
            Введите адрес электронной почты, с помощью которого вы
            регистрировались на сайте. Письмо с инструкцией по восстановлению
            пароля придет в течение минуты.
          </T>
        </BodyM>
      </div>
      <ModalFooter>
        <Button size="medium" look="tertiary" onClick={signUpHandler}>
          Зарегистрироваться
        </Button>
        <Button size="medium" type="submit" disabled={isLoading}>
          Восстановить
        </Button>
      </ModalFooter>
    </form>
  ) : (
    <RestoreStepTwo
      email={email}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    />
  );
};
