import { DEFAULT_RESIDENT_COUNTRY } from 'entities/Profile/constants';
import {
  DOCS_DIRECT_AGREEMENT,
  DOCS_NOT_AUTH,
  DOCS_OFFER_NOT_RESIDENT,
  DOCS_OFFER_RESIDENT_BASE,
  DOCS_OFFER_RESIDENT_UPGRADE,
  getFooterMenu,
} from 'modules/footer/constants';
import type { IFooterType } from 'modules/footer/types';
import { useMemo, FC } from 'react';
import { useSelector } from 'react-redux';

import type { TAppState } from 'sp-redux';
import { EAccountRoyaltyOfferStatus } from 'sp-redux/slices/userInfo/types';

import { FooterBottomList } from '../FooterBottomList';
import { FooterContacts } from '../FooterContacts';
import { FooterList } from '../FooterList';

import s from './Footer.module.scss';

export const Footer: FC = () => {
  const { userInfo }: TAppState = useSelector(
    (appState: TAppState) => appState,
  );

  const {
    isLogged,
    profile: {
      has_direct_agreement,
      account_royalty_offer_status,
      resident_country,
    },
  } = userInfo;
  const isResident = resident_country === DEFAULT_RESIDENT_COUNTRY;

  const footerDocs = useMemo((): IFooterType => {
    if (!isLogged) {
      return DOCS_NOT_AUTH;
    } else {
      if (has_direct_agreement) {
        return DOCS_DIRECT_AGREEMENT;
      } else {
        // Оферта
        if (isResident) {
          // Резидент
          if (
            account_royalty_offer_status === EAccountRoyaltyOfferStatus.advanced
          ) {
            return DOCS_OFFER_RESIDENT_UPGRADE;
          } else if (!!account_royalty_offer_status) {
            // воспринимаем pending_advanced как basic
            return DOCS_OFFER_RESIDENT_BASE;
          }
        } else {
          // Нерезидент
          return DOCS_OFFER_NOT_RESIDENT;
        }
      }
    }
    return { list: [] };
  }, [
    isLogged,
    has_direct_agreement,
    account_royalty_offer_status,
    isResident,
  ]);

  const footerMenu = useMemo(() => {
    const withPublisherLink = userInfo.is_publisher || !isLogged;
    return getFooterMenu(withPublisherLink);
  }, [isLogged, userInfo]);

  return (
    <footer className={s.root}>
      <div className={s.innerWrapper}>
        <section className={s.social}>
          <FooterContacts />
        </section>

        <div className={s.menusWrapper}>
          {footerMenu.map(({ list, key }) => {
            return (
              <section className={s.section} key={key}>
                <FooterList list={list} />
              </section>
            );
          })}
        </div>
      </div>

      <FooterBottomList list={footerDocs.list} />
    </footer>
  );
};
