import {
  sendGoogleAnalytics,
  sendYandexAnalytics,
} from 'analytics/sendAnalytics';
import {
  IFooterAnalyticsType,
  IFooterListItemType,
} from 'modules/footer/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ALink } from 'components/UIKit/ALink';

import { TAppState } from 'sp-redux';

import s from './FooterList.module.scss';

interface IFooterListProps {
  list: IFooterListItemType[];
}

export const FooterList: FC<IFooterListProps> = ({ list }) => {
  const userProfile = useSelector((state: TAppState) => state.userInfo.profile);

  const handleLinkClick = (analytics?: IFooterAnalyticsType | null): void => {
    if (analytics) {
      sendGoogleAnalytics({
        action: analytics.action,
        name: analytics.name,
        category: analytics.category,
        label: window.location.href,
      });
      sendYandexAnalytics({
        action: analytics.action,
        goalParams: { user_id: userProfile.uuid ?? '' },
      });
    }
  };

  return (
    <ul className={s.root}>
      {list.map(({ href = undefined, text, analytics, target, rel }) => {
        return (
          <li className={s.listItem} key={text}>
            {href && (
              <ALink
                className={s.link}
                href={href}
                target={target}
                rel={rel}
                onClick={(): void => handleLinkClick(analytics)}
              >
                {text}
              </ALink>
            )}
          </li>
        );
      })}
    </ul>
  );
};
