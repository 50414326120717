import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { Caption } from 'components/basic/Typography';

import s from './ValidationPasswordText.module.scss';

interface IValidationPasswordTextProps {
  password: string;
}

type TValidationOptions = 'red' | 'orange' | 'green' | null;

export const ValidationPasswordText: FC<IValidationPasswordTextProps> = ({
  password,
}) => {
  const [validationOption, setValidationOption] =
    useState<TValidationOptions>(null);

  useEffect(() => {
    if (password.length === 0) {
      setValidationOption(null);
      return;
    }

    let currentValidationOption: TValidationOptions = 'red';
    const hasUppercaseOrDigit = /[A-ZА-Я0-9]/i.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(
      password,
    );

    if (password.length >= 8 && hasUppercaseOrDigit) {
      currentValidationOption = 'orange';
    }

    if (password.length >= 8 && hasUppercaseOrDigit && hasSpecialCharacter) {
      currentValidationOption = 'green';
    }

    setValidationOption(currentValidationOption);
  }, [password]);

  const getMessage = (): string => {
    switch (validationOption) {
      case 'red':
        return 'Легкий пароль';
      case 'orange':
        return 'Средний пароль';
      case 'green':
        return 'Сложный пароль';
      default:
        return '';
    }
  };

  return (
    <div className={s.wrapper}>
      {validationOption && (
        <Caption className={s[validationOption]}>{getMessage()}</Caption>
      )}
    </div>
  );
};
