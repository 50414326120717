import type { FC } from 'react';

import { Modal, useModal } from 'components/complex/Modal';
import { INNForm } from 'components/complex/pages/status/DialogINN/INNForm';

import { EUiModalTypes } from 'sp-redux/slices/ui/types';

export const DialogINN: FC = () => {
  const { modalProps } = useModal({
    type: EUiModalTypes.dialogINN,
    title: 'Заполните форму',
    width: '368px',
  });

  return (
    <Modal {...modalProps}>
      <INNForm />
    </Modal>
  );
};
