import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ALink } from 'components/UIKit/ALink';
import { Button } from 'components/UIKit/Button';
import { BookLoader } from 'components/UIKit/Loaders/BookLoader';
import { BodyM, T } from 'components/basic/Typography';
import { Modal, ModalFooter, useModal } from 'components/complex/Modal';
import { StaticInfo } from 'components/complex/pages/home/StaticInfo';
import type { IStaticPagesType } from 'components/complex/pages/home/StaticInfo/types';

import { EUiModalTypes } from 'sp-redux/slices/ui/types';
import { getUserInfoThunk } from 'sp-redux/thunks/userInfo';

import { doFetch } from 'utils/doFetch';

import s from './DialogOffer.module.scss';

export const DialogOffer: FC = () => {
  const [pageOffer, setPageOffer] = useState<IStaticPagesType>();
  const [error, setError] = useState('');
  const { modalProps, close } = useModal({
    type: EUiModalTypes.authorOffer,
    title: 'Изменения правил публичной оферты',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const apiPointName = 'pagesStatic';
    (async (): Promise<void> => {
      const pageData = await doFetch<typeof apiPointName>({
        apiPointName,
        urlParams: {
          slug: 'offer',
        },
      });
      setPageOffer(pageData.result);
    })();
  }, []);

  async function acceptAnOfferHandler(): Promise<void> {
    const apiPointName = 'acceptAnOffer';
    try {
      await doFetch<typeof apiPointName>({
        apiPointName,
      });
      await dispatch(getUserInfoThunk());
      close();
    } catch (e) {
      setError('Что-то пошло не так...');
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  return (
    <Modal
      {...modalProps}
      className={s.modalCustomStyle}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className={s.wrapperOffer}>
        {pageOffer ? (
          <StaticInfo
            className={s.offerPageBackground}
            staticTitle={pageOffer.name}
            staticText={pageOffer.text}
          />
        ) : (
          <BookLoader />
        )}
      </div>
      <BodyM className={s.goToFormText}>
        <T>Если у вас не оформлен статус самозанятого или ИП,</T>{' '}
        <ALink href="/statistics/natural-person/">
          <T>заполните форму</T>{' '}
        </ALink>{' '}
        .{error && <BodyM className={s.goToFormTextError}>{error}</BodyM>}
      </BodyM>
      <ModalFooter className={s.footerModal}>
        <Button onClick={acceptAnOfferHandler} size="medium">
          Принять
        </Button>
      </ModalFooter>
    </Modal>
  );
};
