import { pageUrls, publisherUrls } from 'constants/pagesUrls';

import { createUTMUrl } from 'utils/createUTMUrl';

import { IFooterConfig, IFooterListItemType } from './types';

const footerAbout: IFooterListItemType[] = [
  {
    text: 'О сервисе',
    href: pageUrls.about.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'Footer',
      category: 'Footer',
      action: 'Service',
    },
  },
  {
    text: 'Книжная премия Литрес',
    href: createUTMUrl(pageUrls.ebukva.index, {
      source: 'selfpub',
      medium: 'footer',
      campaign: 'ebukva2024',
    }),
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'Footer',
      category: 'Footer',
      action: 'Prize',
    },
  },
  {
    text: 'Конкурсы',
    href: createUTMUrl(pageUrls.blog.promo, {
      source: 'selfpub',
      medium: 'footer',
      campaign: 'contest',
    }),
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'Footer',
      category: 'Footer',
      action: 'Contest',
    },
  },
  {
    text: 'Наши партнеры',
    href: pageUrls.domains.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'Footer',
      category: 'Footer',
      action: 'Partners',
    },
  },
  {
    text: 'Поддержка',
    href: pageUrls.feedback.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'Footer',
      category: 'Footer',
      action: 'Feedback',
    },
  },
  {
    text: 'Контакты',
    href: pageUrls.contacts.index,
    target: '_blank',
    rel: 'nofollow noopener',
  },
];

const footerFaqItems: IFooterConfig = {
  key: 'footerFaqItems',
  list: [
    {
      text: 'Первые шаги и работа с сервисом',
      href: pageUrls.faq.firstStep,
      target: '_blank',
      rel: 'nofollow noopener',
      analytics: {
        name: 'Footer',
        category: 'Footer',
        action: 'FirstStep',
      },
    },
    {
      text: 'Публикация электронной книги',
      href: pageUrls.faq.ebook,
      target: '_blank',
      rel: 'nofollow noopener',
      analytics: {
        name: 'Footer',
        category: 'Footer',
        action: 'Ebook',
      },
    },
    {
      text: 'Публикация аудиокниги',
      href: pageUrls.faq.publishaudio,
      target: '_blank',
      rel: 'nofollow noopener',
      analytics: {
        name: 'Footer',
        category: 'Footer',
        action: 'Audiobook',
      },
    },
    {
      text: 'Публикация черновика',
      href: pageUrls.faq.draft,
      target: '_blank',
      rel: 'nofollow noopener',
      analytics: {
        name: 'Footer',
        category: 'Footer',
        action: 'Draft',
      },
    },
    {
      text: 'Частые вопросы',
      href: pageUrls.faq.index,
      target: '_blank',
      rel: 'nofollow noopener',
      analytics: null,
    },
    {
      text: 'Блог',
      href: createUTMUrl(pageUrls.blog.index, {
        source: 'selfpub',
        medium: 'footer',
        campaign: 'blog',
      }),
      target: '_blank',
      rel: 'nofollow noopener',
      analytics: {
        name: 'Footer',
        category: 'Footer',
        action: 'Blog',
      },
    },
  ],
};

export const PUBLISHER_FOOTER_MENU_ITEM: IFooterListItemType = {
  text: 'Кабинет издателя',
  href: publisherUrls.login,
};

export const DOCS = {
  PrivacyPolicyResident: {
    text: 'Политика конфиденциальности для резидентов РФ',
    href: pageUrls.privacy.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'All-pages',
      category: 'All-pages',
      action: 'Privacy',
    },
  },
  PrivacyPolicyNotResident: {
    text: 'Политика конфиденциальности для нерезидентов РФ',
    href: pageUrls.privacy_litres_operations.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'All-pages',
      category: 'All-pages',
      action: 'Privacy-litres-operations',
    },
  },
  OfferResidentBase: {
    text: 'Оферта для резидентов РФ',
    href: pageUrls.offer.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'All-pages',
      category: 'All-pages',
      action: 'Offer',
    },
  },
  OfferResidentUpgrade: {
    text: 'Оферта для резидентов РФ',
    href: pageUrls.offer_upgrade.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'All-pages',
      category: 'All-pages',
      action: 'Offer',
    },
  },
  OfferNotResident: {
    text: 'Оферта для нерезидентов РФ',
    href: pageUrls.offer_non_resident.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'All-pages',
      category: 'All-pages',
      action: 'Offer-non-resident',
    },
  },
  OfferNotResidentUpgrade: {
    text: 'Оферта для нерезидентов РФ',
    href: pageUrls.non_resident_offer_upgrade.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'All-pages',
      category: 'All-pages',
      action: 'Offer-non-resident',
    },
  },
  Agreement: {
    text: 'Договор оказания услуг',
    href: pageUrls.agreement.index,
    target: '_blank',
    rel: 'nofollow noopener',
    analytics: {
      name: 'All-pages',
      category: 'All-pages',
      action: 'Agreement',
    },
  },
};

export const phone = {
  text: '8 (800) 333 27 37',
  href: 'tel:+78003332737',
  title: 'Контактный телефон',
  description: 'Ежедневно с 10:00 до 22:00 по Москве',
  analytics: {
    name: 'Footer',
    category: 'Contact',
    action: 'Phone',
  },
};

export const getFooterMenu = (
  withPublisherLink: boolean,
): [{ list: IFooterListItemType[]; key: string }, IFooterConfig] => {
  if (withPublisherLink) {
    // Нам нужно порвать связь с дочерними объектами
    // в footerFaqItems, чтобы не мутировать исходный объект
    const updatedFooterFaqItems: IFooterConfig = JSON.parse(
      JSON.stringify(footerFaqItems),
    );
    updatedFooterFaqItems.list.push(PUBLISHER_FOOTER_MENU_ITEM);

    return [
      {
        key: 'about',
        list: footerAbout,
      },
      updatedFooterFaqItems,
    ];
  }
  return [
    {
      key: 'about',
      list: footerAbout,
    },
    footerFaqItems,
  ];
};

export const DOCS_NOT_AUTH = {
  list: [
    DOCS.PrivacyPolicyResident,
    DOCS.PrivacyPolicyNotResident,
    DOCS.OfferNotResident,
    DOCS.OfferResidentBase,
    DOCS.Agreement,
  ],
};

export const DOCS_DIRECT_AGREEMENT = {
  list: [
    DOCS.PrivacyPolicyResident,
    DOCS.PrivacyPolicyNotResident,
    DOCS.Agreement,
  ],
};

export const DOCS_OFFER_RESIDENT_BASE = {
  list: [DOCS.PrivacyPolicyResident, DOCS.OfferResidentBase, DOCS.Agreement],
};

export const DOCS_OFFER_RESIDENT_UPGRADE = {
  list: [DOCS.PrivacyPolicyResident, DOCS.OfferResidentUpgrade, DOCS.Agreement],
};

export const DOCS_OFFER_NOT_RESIDENT = {
  list: [DOCS.PrivacyPolicyNotResident, DOCS.OfferNotResident, DOCS.Agreement],
};
