import { useValidation } from 'hooks/useValidation';
import type { FormEvent, FC } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import type { SchemaOf } from 'yup';

import { Button } from 'components/UIKit/Button';
import { InputText } from 'components/UIKit/InputText';
import { Switcher } from 'components/UIKit/Switcher';
import { BodyM, T } from 'components/basic/Typography';
import { ModalFooter } from 'components/complex/Modal';
import type {
  IModalPropsTypes,
  ISignSpecialOfferFormType,
} from 'components/complex/forms/SpecialOfferForm/types';

import { closeModal } from 'sp-redux/slices/ui';

import s from './SpecialOfferForm.module.scss';

export const SpecialOfferForm: FC<IModalPropsTypes> = ({
  setSendFormStatus,
}) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [pseudonym, setPseudonym] = useState('');
  const [publicationYear, setPublicationYear] = useState('');
  const [publishingHouse, setPublishingHouse] = useState('');
  const [isErrorsApi, setIsErrorsApi] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();

  const schema: SchemaOf<ISignSpecialOfferFormType> = yup.object().shape({
    lastName: yup.string().trim().min(1).required('Укажите фамилию и имя'),
    email: yup
      .string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Некорректный адрес',
      )
      .required('Введите корректный адрес почты'),
    pseudonym: yup.string().trim(),
  });

  const { validationErrors, validateAllFieldsAsync, debouncedValidate } =
    useValidation<ISignSpecialOfferFormType>(
      { lastName: fullName, pseudonym, email },
      schema,
    );

  const closeModalHandler = (): void => {
    dispatch(closeModal());
  };

  function switcherHandler(): void {
    if (isActive && publicationYear.length > 0) {
      setPublicationYear('');
    }
    if (isActive && publishingHouse.length > 0) {
      setPublishingHouse('');
    }
    setIsActive(!isActive);
  }

  async function signSpecial(evt: FormEvent): Promise<void> {
    evt.preventDefault();
    const isHaveValidationErrors = await validateAllFieldsAsync();

    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    if (!isHaveValidationErrors) {
      try {
        const date = new Date().toDateString();
        // пишем через обычный феч, так как через наш хук будет добавляться наш урл selfpub
        await fetch(`https://hooks.zapier.com/hooks/catch/92225/bf3w3iz/`, {
          method: 'post',
          body: JSON.stringify({
            date,
            fio: fullName,
            email: email,
            alias: pseudonym,
            havebook: isActive,
            year: publicationYear,
            publish: publishingHouse,
          }),
        });
        setSendFormStatus(true);
        closeModalHandler();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setIsErrorsApi(true);
      }
    }
  }

  return (
    <form onSubmit={signSpecial}>
      <div className={s.root}>
        <InputText
          labelText="Фамилия имя (полностью)"
          value={fullName}
          onChange={(value): void => {
            setFullName(value);
          }}
          debouncedValidate={(): void => {
            debouncedValidate('lastName');
          }}
          errorMessage={validationErrors.lastName}
        />
        <InputText
          labelText="Ваша электронная почта"
          value={email}
          onChange={(value): void => {
            setEmail(value);
          }}
          debouncedValidate={(): void => {
            debouncedValidate('email');
          }}
          errorMessage={validationErrors.email}
          autocomplete="email"
        />
        <InputText
          labelText="Псевдоним, если есть"
          value={pseudonym}
          onChange={(value): void => {
            setPseudonym(value);
          }}
          debouncedValidate={(): void => {
            debouncedValidate('pseudonym');
          }}
          errorMessage={validationErrors.pseudonym}
        />
        <div className={s.switcher}>
          <Switcher switcherHandler={switcherHandler} isActive={isActive} />
          <span className={s.textSwitcher}>Есть публикации на бумаге</span>
        </div>
        <InputText
          labelText="В каком году публиковались"
          value={publicationYear}
          isDisabled={!isActive}
          onChange={(value): void => {
            setPublicationYear(value);
          }}
        />
        <InputText
          labelText="Где публиковались"
          value={publishingHouse}
          isDisabled={!isActive}
          onChange={(value): void => {
            setPublishingHouse(value);
          }}
        />
      </div>
      {isErrorsApi && (
        <BodyM className={s.apiErrors}>
          <T>Что-то пошло не так...</T>
        </BodyM>
      )}
      <ModalFooter>
        <Button size="medium" look="tertiary" onClick={closeModalHandler}>
          Отмена
        </Button>
        <Button size="medium" type="submit">
          Запросить
        </Button>
      </ModalFooter>
    </form>
  );
};
